import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    position: "relative",
    zIndex: "1",
    overflow: "hidden",
  },
  LoginLayout: {
    minHeight: "calc(100vh - 0px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logoBox: {
    marginBottom: "25px",
    cursor: "pointer",
  },
  logoImg: {
    width: "150px",
    objectFit: "contain",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      <Box className="loginLeftSahdow"></Box>
      <Box className="loginRightSahdow"></Box>

      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.LoginLayout}>
        <Box onClick={() => history.push("/")} className={classes.logoBox}>
          <Logo className={classes.logoImg} />
        </Box>
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
