// export const baseurl = "http://172.16.1.131:1978"; //local URL
// export const baseurl = "https://nodeasad-icodashboard.mobiloitte.io"; //Staging URL
// export const baseurl = "https://node.rapidrush.io"; //Master URL
export const baseurl = process.env.REACT_APP_BASE_URL;
let url = `${baseurl}/api/v1`;

const ApiConfig = {
  connectWallet: `${url}/user/connectWallet`,
  getProfileUser: `${url}/user/getProfileUser`,
  editProfile: `${url}/user/editProfile`,

  // //// //// admin-----------
  login: `${url}/admin/login`,
  forgotPassword: `${url}/admin/forgotPassword`,
  resetPasswordAdmin: `${url}/admin/resetPasswordAdmin`,

  staticContent: `${url}/static/staticContent`,
  staticContentList: `${url}/static/staticContentList`,

  ///nft///
  createNFT: `${url}/nft/createNFT`,
  nftList: `${url}/nft/nftList`,

  // admin management
  userList: `${url}/admin/userList`,
  userBlockUnblock: `${url}/admin/blockUnblockUser`,
  transactionManagement: `${url}/admin/mintingTransactionList`,
};
export default ApiConfig;
//
//
