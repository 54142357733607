import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import {
  ACTIVE_NETWORK,
  RPC_URL,
  carGenerativeNFTContract,
  characterGenerativeNFTContract,
  hoverBoardGenerativeNFTContract,
  bikeGenerativeNFTContract,
} from "src/constants";
import { injected } from "src/connectors";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import {
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import BothgenerativeNFTABI from "src/ABI/BothgenerativeNFTABI.json";
import CharacterNftAbi from "src/ABI/CharacterNftAbi.json";
import { getAPIHandler, postAPIHandler } from "src/ApiConfig/service";
import web3 from "web3";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}
function checkAdminLogin() {
  const accessToken = window.localStorage.getItem("admin_token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isAdminLogin, setIsAdminLogin] = useState(checkAdminLogin());
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const [mintDataLoad, setMintDataLoad] = useState(false);
  const [openMetamask, setOpenMetamask] = React.useState(false);
  const [isibiRequire, setisibiRequire] = React.useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [maxNftSupply, setMaxNftSupply] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [perNftPrice, setPerNftPrice] = useState("");
  const [selectCategory, setSelectCategory] = useState("AVATAR");
  const [userData] = useState({});

  const walletConnect = async (refreral) => {
    try {
      setMetaMaskLoading(true);
      activate(injected, undefined, true)
        .then(() => {
          window.sessionStorage.setItem("ConnectWallet", true);
          setMetaMaskLoading(false);
          setOpenMetamask(false);
        })
        .catch((error) => {
          if (error) {
            toast.error(error.message);
            console.log("ERROR", error);
            setMetaMaskLoading(false);
            setOpenMetamask(false);
            const errorMSG = error.message; //+ ' Please install Metamask';
            activate(injected);
          }
        });
    } catch (error) {
      console.log("ERROR", error);
      setMetaMaskLoading(false);
    }
  };

  const connnectWalletApi = async (account) => {
    try {
      const response = await postAPIHandler({
        endPoint: "connectWallet",
        dataToSend: {
          walletAddress: account,
        },
      });
      if (response?.data?.responseCode === 200) {
        window.localStorage.setItem("token", response?.data?.data?.token);
        setSession(response?.data?.data?.token);
        GetProfileUser(response?.data?.data?.token);
        // viewUserProfile();
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
    }
  };
  const GetProfileUser = async (token) => {
    try {
      const response = await getAPIHandler({
        endPoint: "getProfileUser",
        headers: {
          token: token,
        },
      });
      if (response?.data?.responseCode === 200) {
        // window.localStorage.setItem("token", response?.data?.data?.token);
        setisibiRequire(!response?.data?.data?.isibiRequire);
        // viewUserProfile();
        // setisibiRequire()
      } else {
        toast.error(response?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.responseMessage);
    }
  };

  const getOurWalletBlc = async () => {
    const web3 = getWeb3Obj(RPC_URL);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      toast.success("You have been disconnected successfully!");
      window.sessionStorage.removeItem("ConnectWallet");
      window.localStorage.removeItem("token");
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentMintingDetails = async (checkCategory) => {
    try {
      setMintDataLoad(true);
      const checkContractOjb =
        checkCategory == "AVATAR"
          ? getWeb3ContractObject(
              CharacterNftAbi,
              characterGenerativeNFTContract
            )
          : checkCategory == "CAR"
          ? getWeb3ContractObject(
              BothgenerativeNFTABI,
              carGenerativeNFTContract
            )
          : checkCategory == "HOVERBOARD"
          ? getWeb3ContractObject(
              BothgenerativeNFTABI,
              hoverBoardGenerativeNFTContract
            )
          : getWeb3ContractObject(
              BothgenerativeNFTABI,
              bikeGenerativeNFTContract
            );
      const maxTotalNftSupply = await checkContractOjb.methods
        .MAX_NFT_SUPPLY()
        .call();
      setMaxNftSupply(Number(maxTotalNftSupply));
      const totalSupply = await checkContractOjb.methods.totalSupply().call();
      setTotalSupply(Number(totalSupply));
      const perNftPriceSupply = await checkContractOjb.methods
        .NFT_PRICE_ROUND1()
        .call();
      setPerNftPrice(Number(web3.utils.fromWei(perNftPriceSupply.toString())));
      setMintDataLoad(false);
    } catch (error) {
      console.log(error);
      setMintDataLoad(false);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [sessionStorage.getItem("ConnectWallet")]);

  useEffect(() => {
    if (account && chainId) {
      getOurWalletBlc();
      connnectWalletApi(account);
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  useEffect(() => {
    if (selectCategory) {
      getCurrentMintingDetails(selectCategory);
    }
  }, [selectCategory]);

  let data = {
    userLoggedIn: isLogin,
    adminLoggedIn: isAdminLogin,
    userData,
    account,
    library,
    chainId,
    perNftPrice,
    maxNftSupply,
    totalSupply,
    openMetamask,
    selectCategory,
    mintDataLoad,
    setSelectCategory,
    yourWalletBalance,
    metaMaskLoading,
    setOpenMetamask,
    isibiRequire,
    setisibiRequire,
    walletConnect: walletConnect,
    checkAdminLogin: checkAdminLogin,
    setIsAdminLogin: (e) => setIsAdminLogin(e),
    disconnectWalletHandler: disconnectWalletHandler,
    getCurrentMintingDetails: getCurrentMintingDetails,
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
