import axios from "axios";
import ApiConfig from "../ApiConfig";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const putAPIHandlerCall = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAPIHandler = async ({ endPoint, id, source }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getparamsAPIHandler = async ({ endPoint, id, source }) => {
  try {
    return await axios({
      method: "GET",
      url:  ApiConfig[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      params:id,
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const apiRouterCall = async ({
  method,
  endPoint,
  bodyData,
  paramsData,
  id,
  token,
}) => {
  try {
    const res = await axios({
      method: method,
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      headers: {
        token: window?.localStorage?.getItem("admin_token"),
      },
      params: paramsData ? paramsData : null,
      data: bodyData ? bodyData : null,
    });
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};