import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Box } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import Footer from "./Footer";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    backgroundColor: "#fff",
    position: "relative",
  },
  root1: {
    backgroundColor: "#000",
    position: "relative",
    height: "100vh",
  },
  wrapper1: {
    backgroundColor: "#000",
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    // paddingTop: 70,
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
    paddingTop: 70,
    minHeight: "calc(100vh - 75px)",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    "@media (max-width:767px)": {
      paddingTop: "70px !important",
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    padding: "100px 40px 25px ",
    "@media (max-width: 1280px)": {
      padding: "100px 33px 20px ",
    },
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px 10px ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 24px 10px ",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        className={
          themeSeeting.settings.theme === "DARK"
            ? `${classes.wrapper1}`
            : `${classes.wrapper}`
        }
      >
        <div className={classes.contentContainer}>
          <Box className="loginLeftSahdow"></Box>
          <Box className="loginRightSahdowdash"></Box>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
