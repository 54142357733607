import React from "react";
import {
  Box,
  TableRow,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box>
      <Divider className="borderBox" />
      <Box className="displayCenter">
        <Typography
          variant="body2"
          style={{ color: "rgba(255, 255, 255, 0.6)" }}
        >
          Copyright©2023. Created with love by{" "}
          <span className="gradientfirexText" style={{ fontSize: "18px" }}>
            FIERES
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
