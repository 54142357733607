import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Slide,
  Dialog,
  DialogContent,
  DialogActions,
  Avatar,
  Hidden,
  SwipeableDrawer,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import SettingsContext from "src/context/SettingsContext";
import ConnectModal from "src/component/ConnectModal";
import { RxCross2 } from "react-icons/rx";
import { AiFillCaretDown } from "react-icons/ai";
import { withStyles } from "@material-ui/core/styles";
import AnnouncementCard from "src/component/AnnouncementCard";
import { IoClose } from "react-icons/io5";
import { AuthContext } from "src/context/Auth";
import { sortAddress } from "src/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import toast from "react-hot-toast";
import { putAPIHandlerCall } from "src/ApiConfig/service";
import { Form, Formik } from "formik";
import * as yup from "yup";

const headersData = [
  {
    label: "Race",
    href: "/car-selection",
  },
  {
    label: "Leaderboard",
    href: "/leaderboard",
  },
  {
    label: "Garage",
    href: "/shop",
  },
  // {
  //   label: "Live",
  //   href: "/live-and-upcomming",
  // },
  // {
  //   label: "Learn More",
  //   href: "/learn-more",
  // },
  // {
  //   label: "Improvement",
  //   href: "/improvement",
  // },
  // {
  //   label: "Login",
  //   href: "/login",
  // },
];
const announcement = [{}, {}, {}];
const useStyles = makeStyles((theme) => ({
  menubox: {
    "& ul": {
      width: "136px",
      borderRadius: "5px",
      backgroundColor: "#212121 !important",
      background: "#110720",
      "& a": {
        fontSize: "15px",
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "500",
        color: "#B7B7B7",
        "& .active": {
          color: "#fff",
        },
        "&:hover": {
          color: "#fff",
        },
      },
    },
  },
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "& .active": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "&:hover": {
      background:
        "linear-gradient(93.34deg, #FF6F37 6.82%, #FF2676 35.9%, #B801AA 68.08%, #7101BC 101.4%)",
      textShadow: "3px 3px 3px rgb(0 0 0 / 13%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
  },

  toolbar: {
    display: "flex",
    padding: "0px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
    display: "flex",
  },
  logoImg: {
    width: "150px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "100px",
    },
  },
  menuMobile: {
    fontSize: "14px !important",
    fontWeight: "400",
    fontStyle: "normal",
    // letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    marginBottom: "8px",
    width: "100%",
    background: "rgba(255, 255, 255, 0.05)",
    // borderBottom: "1px solid #3e3e3e",
    padding: "10px",
    "@media (max-width: 600px)": {
      width: "100%",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.05)",
      borderRadius: "10px",
      padding: "16px 0px",
    },
  },

  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  drawerPaper: {
    width: "100%",
    height: "100%",
  },
  IconButtonBox: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  sidebarBox: {
    maxWidth: "400px",
    width: "100%",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "355px",
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open11, setOpen11] = React.useState(false);

  const handleDrawerOpen11 = () => {
    setOpen11(true);
  };

  const handleDrawerClose11 = () => {
    setOpen11(false);
  };
  const classes = useStyles();
  const StyledMenu = withStyles({
    paper: {
      // border: "1px solid #d3d4d5",
      marginTop: "20px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));
  const {
    menuMobile,
    menuButton,
    toolbar,
    menubox,
    drawerContainer,
    drawericon,
    IconButtonBox,
    sidebarBox,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [disconnect, setDisconnect] = useState(false);
  // const [disconnect, setDisconnect] = useState(false);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  const loginSubmit = async (values) => {
    try {
      const response = await putAPIHandlerCall({
        endPoint: "editProfile",
        dataToSend: {
          ibiName: values.IBIName,
          ibiId: values.IBIId,
        },
      });

      if (response?.data?.responseCode === 200) {
        auth.setisibiRequire(false);
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.responseMessage);
        auth.setisibiRequire(false);
      }
    } catch (err) {
      console.log(" err ", err);
      auth.setisibiRequire(false);
    }
  };
  const initialFormValues = {
    IBIName: "",
    IBIId: "",
  };
  const validationFormSchema = yup.object().shape({
    IBIId: yup.string().required("IBI Id is required"),
    IBIName: yup.string().required("IBI Name is required"),
  });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const connectWalletButton = (
    <Button
      variant="contained"
      color="primary"
      style={{ marginLeft: "17px" }}
      onClick={() =>
        !auth.account
          ? auth.setOpenMetamask(true)
          : auth.disconnectWalletHandler()
      }
    >
      {auth.account ? (
        <>
          {sortAddress(auth.account)}
          {/* <CopyToClipboard text={auth.account}>
            <IconButton>
              <FaCopy
                style={{
                  fontSize: "16px",
                  color: "#FFCA64",
                  cursor: "pointer",
                }}
                onClick={() => toast.success("Copied")}
              />
            </IconButton>
          </CopyToClipboard> */}
        </>
      ) : (
        "Connect Wallet"
      )}
    </Button>
  );

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className={classes.logoImg} />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          className={`${classes.menuButton} ${
            window.location.pathname === href && "active"
          }`}
          // onClick={() => history.push(`${href}`)}
          onClick={() => toast.success("Coming Soon!")}
        >
          {label}
        </Button>
      );
    });
  };

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {getMenuButtons()}
            {connectWalletButton}
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              className={menubox}
              onClose={handleClose1}
            >
              <MenuItem
                component={Link}
                onClick={() => history.push("/setting")}
              >
                Profile
              </MenuItem>
              <MenuItem component={Link} onClick={handleDrawerOpen11}>
                Announcements
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => {
                  setDisconnect(true);
                  setAnchorEl(null);
                }}
              >
                Disconnect
              </MenuItem>
            </StyledMenu>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Hidden xsDown>
          <Drawer
            {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>
              {femmecubatorLogo}
              {getDrawerChoices()}
              {connectWalletButton}
            </div>
          </Drawer>
        </Hidden>
        <Hidden smUp>
          <SwipeableDrawer
            {...{
              anchor: "bottom",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={drawerContainer}>
              <div className={classes.IconButtonBox}>
                <IconButton onClick={() => handleDrawerClose()}>
                  <RxCross2 />
                </IconButton>
              </div>
              {femmecubatorLogo}
              {getDrawerChoices()}
              {connectWalletButton}
            </div>
          </SwipeableDrawer>
        </Hidden>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={4}>
            <Box className={drawericon}>
              <Box
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  display: "none",
                }}
                onClick={handleClick}
                mr={1}
              >
                <Avatar src="images/mgpro.png" />
                <AiFillCaretDown style={{ color: "#fff", fontSize: "15px" }} />
              </Box>
              <IconButton
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#ff2676", fontSize: "30px" }}
                />
              </IconButton>
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                className={menubox}
                onClose={handleClose1}
              >
                <MenuItem component={Link} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem component={Link} onClick={handleDrawerOpen11}>
                  Announcements
                </MenuItem>
                <MenuItem
                  component={Link}
                  onClick={() => {
                    setDisconnect(true);
                    setAnchorEl(null);
                  }}
                >
                  Disconnect
                </MenuItem>
              </StyledMenu>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            className={`${classes.menuMobile} ${
              window.location.pathname === href && "active"
            }`}
            onClick={() => toast.success("Coming Soon!")}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ background: "transparent", border: "none" }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={open11}
        onClose={handleDrawerClose11}
        style={{ overflowY: "scroll" }}
      >
        <Box className={sidebarBox} style={{}}>
          <Box>
            <IconButton
              style={{ position: "absolute", top: "0px", right: "5px" }}
              onClick={handleDrawerClose11}
            >
              <IoClose style={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box align="center" py={2}>
            <Typography variant="h4">Announcements</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              {announcement.map(() => (
                <AnnouncementCard />
              ))}
            </Grid>
          </Grid>
          <Box align="right">
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10px 0px" }}
              onClick={() => history.push("/view-all-announcement")}
            >
              View More
            </Button>
          </Box>
        </Box>
      </Drawer>
      <ConnectModal
        handleCloseModal={() => auth.setOpenMetamask(false)}
        openModal={auth.openMetamask}
        auth={auth}
      />
      <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dailogOpen}
        open={disconnect}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDisconnect(false)}
      >
        <Box className="newModalBorder">
          <Box className="mainbox1">
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography
                  variant="h2"
                  style={{
                    fontFamily: "Sora, sans-serif",
                    fontWeight: "300",
                    marginBottom: "16px",
                  }}
                >
                  Dissconnect Wallet
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontFamily: "'Sora', sans-serif" }}
                >
                  Are you sure want to Dissconnect?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="transparentbutton"
                  style={{ padding: "8px 25px" }}
                  // onClick={() => setIsLogout(false)}
                >
                  Confirm
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="outlined"
                  style={{ padding: "8px 25px" }}
                  variant="contained"
                  color="secondary"
                  onClick={() => setDisconnect(false)}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
     {auth.isibiRequire &&( <Dialog
        maxWidth="sm"
        fullWidth
        className={classes.dailogOpen}
        open={auth.isibiRequire}
        TransitionComponent={Transition}
        keepMounted
        // onClose={() => setisibiRequire(false)}
      >
        <Box className="newModalBorder">
          <Box className="mainbox1">
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Formik
                  initialValues={initialFormValues}
                  validationSchema={validationFormSchema}
                  onSubmit={loginSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box mb={1} textAlign={"start"}>
                            <Typography variant="body2">
                              IBI Name{" "}
                              <span style={{ color: "#FD3124" }}>*</span>
                            </Typography>
                          </Box>

                          <FormControl fullWidth className="formControl">
                            <TextField
                              variant="outlined"
                              placeholder="Please enter IBI Name"
                              fullWidth
                              name="IBIName"
                              value={values.IBIName}
                              error={Boolean(touched.IBIName && errors.IBIName)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <FormHelperText error>
                              {touched.IBIName && errors.IBIName}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Box mb={1} textAlign={"start"}>
                            <Typography variant="body2">
                              IBI Id <span style={{ color: "#FD3124" }}>*</span>
                            </Typography>
                          </Box>

                          <FormControl fullWidth className="formControl">
                            <TextField
                              type="text"
                              name="IBIId"
                              variant="outlined"
                              placeholder="Please enter IBI Id"
                              fullWidth
                              value={values.IBIId}
                              error={Boolean(touched.IBIId && errors.IBIId)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <FormHelperText error>
                              {touched.IBIId && errors.IBIId}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Box mt={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="transparentbutton"
                          type="submit"
                          // onClick={() => history.push("/dashboard")}
                        >
                          Confirm
                        </Button>{" "}
                         {/* <Button
                          className="outlined"
                          style={{ padding: "8px 25px" }}
                          variant="contained"
                          color="secondary"
                          onClick={() => auth.setisibiRequire(false)}
                        >
                          Cancel
                        </Button>  */}
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </DialogContent>
            {/* <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  className="transparentbutton"
                  style={{ padding: "8px 25px" }}
                  // onClick={() => setIsLogout(false)}
                >
                  Confirm
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="outlined"
                  style={{ padding: "8px 25px" }}
                  variant="contained"
                  color="secondary"
                  onClick={() => setisibiRequire(false)}
                >
                  Cancel
                </Button>
              </Box>
            </DialogActions> */}
          </Box>
        </Box>
      </Dialog>)}
    </>
  );
}
