import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { BiMoneyWithdraw } from "react-icons/bi";
import {
  FaBarcode,
  FaEuroSign,
  FaRegUser,
  FaSquarespace,
  FaUserAlt,
} from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Avatar,
  Divider,
} from "@material-ui/core";
import { AiFillDashboard } from "react-icons/ai";
import { BiTransfer } from "react-icons/bi";

import NavItem from "./NavItem";

import { useContext } from "react";
import { AuthContext } from "src/context/Auth";
// import Dashboard from "src/icons/Dashboard";

const sections = [
  {
    items: [
      // {
      //   title: "Dashboard",
      //   icon: MdDashboard,
      //   href: "/dashboard",
      // },
      // {
      //   title: "Sub Admin Management",
      //   icon: HiUsers,
      //   href: "/sub-admin-mangement",
      // },
      {
        title: "User Management",
        icon: FaUserAlt,
        href: "/usermanagement",
      },
      {
        title: "Withdraw Management",
        icon: FaEuroSign,
        href: "/withdraw-management",
      },
      {
        title: "Transaction Management",
        icon: BiTransfer,
        href: "/transaction-managemet",
      },
      // {
      //   title: "Event Management",
      //   icon: MdEmojiEvents,
      //   href: "/event-managemet",
      // },
      // {
      //   title: "Garage Management",
      //   icon: IoMdHammer,
      //   href: "/garage-managemet",
      // },
      // {
      //   title: "Announcement Management",
      //   icon: HiSpeakerphone,
      //   href: "/announcement-management",
      // },
      {
        title: "Static Content Management",
        icon: AiFillDashboard,
        href: "/static",
      },
      // {
      //   title: "Settings",
      //   icon: MdSettings,
      //   href: "/setting",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#000000",
  },
  desktopDrawer: {
    top: "78px",
    width: "245px",
    height: "100% ",
    padding: "10px",
    // margin: "5px 10px 10px 15px",
    background: "#1C191C",

    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    // marginTop: "0px",
    // marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    left: "30px",
    bottom: "100px",
    display: "flex",
    position: "absolute",
    fontSize: "13px",
    background: "transparent",
    alignItems: "center",
    fontWeight: "400",
    justifyContent: "start",
    [theme.breakpoints.down("xs")]: {
      bottom: "-80px",
      left: "37px",
    },
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const handleLogout = () => {
    window.localStorage.removeItem("admin_token");
    auth.setIsAdminLogin(false);
    history.push("/");
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {/* <Box className="displayCenter" flexDirection="column" mb={2} mt={2}>
              <Box>
                <img src="images/userImg.png" alt="userImg" width="99px" />
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="body2">Faizan Faizi</Typography>
              </Box>

              <Divider width="95%" />
            </Box> */}

            {sections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  img: section.img,
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          </Box>
        </Box>

        <Button onClick={() => handleLogout()} className={classes.logoutButton}>
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <Box className="newModalBorder">
              <Box className="mainbox1">
                <DialogContent>
                  <Box className={classes.dialougTitle} align="center">
                    <Typography
                      variant="h2"
                      style={{
                        fontFamily: "Sora, sans-serif",
                        fontWeight: "300",
                        marginBottom: "16px",
                      }}
                    >
                      Log Out
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ fontFamily: "'Sora', sans-serif" }}
                    >
                      Are you sure want to logout?
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mt={2} mb={2} maxWidth="400px">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="transparentbutton"
                      style={{ padding: "8px 25px" }}
                      onClick={() => setIsLogout(false)}
                    >
                      Confirm
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      className="outlined"
                      style={{ padding: "8px 25px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => history.push("/")}
                    >
                      Cancel
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
